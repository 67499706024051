import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'


import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';


import Meta from '../../components/Meta'


export const LaeyerzScreen = ({match, history}) => {

    
    const dispatch = useDispatch()



    const [newModel, setNewModel] = useState("") 

    const [data, setData] = useState([])






    useEffect(() => {
       

    }, [])





    return(



        <div style={{marginTop:'0px'}}>

            <Meta title={'Laeyerz'} description={'Laeyerz is a python library to build simple RAG applications and Agentic AI systems with minimal coding.'}/>


            <p className='h1'>Laeyerz</p>

            <p className='h5'>Laeyerz is an under development open-source python library designed to help you quickly prototype and visualize LLM applications icnluding RAG and Agentic systems</p>

            <hr />


            <Card style={{padding:'20px', marginBottom:'10px', border:'none'}}>


                <p className='h3'>Getting Started</p>

                <p className='h5'>You can find Laeyerz on github at <a style={{color:'#7b2ff7'}} href='https://github.com/pixagan/Laeyerz'>https://github.com/pixagan/Laeyerz</a></p>

                <p className='h5'>Download the repository and install the dependencies.</p>

                <SyntaxHighlighter language='bash'>
                    git clone https://github.com/pixagan/Laeyerz.git
                    
                </SyntaxHighlighter>

                <p className='h5'>Install the dependencies.</p>

                <SyntaxHighlighter language='bash'>
                    cd laeyerz
                </SyntaxHighlighter>
                <SyntaxHighlighter language='bash'>
                    pip install -r requirements.txt
                </SyntaxHighlighter>

                <p className='h5'>Now use pip install -e . to install the package locally.</p>

                <SyntaxHighlighter language='bash'>
                    pip install -e .
                </SyntaxHighlighter>

            </Card>

            <Card style={{padding:'20px', marginBottom:'10px', border:'none'}}>
                <p className='h3'>Requirements</p>

           

                <Table striped bordered hover style={{margin:'20px'}}>
                    <thead>
                        <tr>
                            <th>Component</th>
                            <th>Supported Technologies</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Session Storage</td>
                            <td>MongoDB</td>
                        </tr>
                        <tr>
                            <td>Vector Database</td>
                            <td>Pinecone</td>
                        </tr>
                        <tr>
                            <td>LLM</td>
                            <td>OpenAI</td>
                        </tr>
                        <tr>
                            <td>Embedding Model</td>
                            <td>OpenAI, HuggingFace</td>
                        </tr>
                    </tbody>
                </Table>
            </Card>
          

          <Card style={{padding:'20px', marginBottom:'10px', border:'none'}}>

            <p className='h3'>To start using Laeyerz</p>

            <p className='h5'>Select the configuration.</p>

            <SyntaxHighlighter language='python'>
 
            {`config = {
                "appname": "Example 1",
                "LLM": {"vendor": "OpenAI", "model": "gpt-4o"},
                "VectorDB": "Pinecone",
                "VectorDB_Params": {
                    "index_name": "example2-index",
                    "namespace": "example2-namespace",
                    "dimension": 384,
                    "metric": "cosine",
                    "spec": "serverless"
                },
                "EmbeddingModel": {"vendor": "HuggingFace", "model_name": "paraphrase-MiniLM-L6-v2"},
                "NoSQL": "Mongo",
                "GraphDB": "None"
            }`}
            </SyntaxHighlighter>

            <p className='h5'>Create an instance of Laeyerz.</p>

            <SyntaxHighlighter language='python'>
                ly = Laeyerz(settings)
            </SyntaxHighlighter>


          </Card>

          <Card style={{padding:'20px', marginBottom:'10px', border:'none'}}>
            <p className='h3'>Laeyers accepts information in the form of documents.</p>

            <SyntaxHighlighter language='python'>
            {`document = {
                "type": "report",
                "title": "Example 1",
                "chunks": [
                    {"text": "Retrieval-Augmented Generation (RAG) is an AI framework that enhances ..."},
                    {"text": "Different LLMs and databases play crucial roles in .."},
                    {"text": "RAG is widely used in applications that ...."}
                ]
            }`}
            </SyntaxHighlighter>

            <p className='h5'>As each document is added Laeyerz will store the document in its memory structure. You can directly start querying the documents.</p>

            <SyntaxHighlighter language='python'>
                ly.add_document(document",'report')
            </SyntaxHighlighter>

            <p className='h5'>To ask questions create a session</p>

            <SyntaxHighlighter language='python'>
                session = ly.create_session("Session Name")
            </SyntaxHighlighter>

          </Card>

          <Card style={{padding:'20px', marginBottom:'10px', border:'none'}}>

            <p className='h3'>Loading an Existing Session</p>

            <SyntaxHighlighter language='python'>
                session = ly.load_session("Session Name")
            </SyntaxHighlighter>

          </Card>

          <Card style={{padding:'20px', marginBottom:'10px', border:'none'}}>
            <p className='h3'>Chat with Laeyerz</p>

            <p className='h5'>Once the session is loaded chat with Laeyerz. It will use the memories and the LLM to respond to the query.</p>

            <SyntaxHighlighter language='python'>
                {`response = curr_session.chat(

                    {   
                        "instructions":"Respond to the user's query based on the provided information.",
                        "query":"What are the applications of RAG?"
                    }
                )`}
            </SyntaxHighlighter>

            <p className='h5'>The response will be stored in the session memory.</p>
            
            
          </Card>

          <Card style={{padding:'20px', marginBottom:'10px', border:'none'}}>

          <p className='h3'>Examples</p>

          <p className='h5'>You can checkout more examples amd use cases in the examples folder of the github repository.</p>

          </Card>


        </div>



    )


}

export default LaeyerzScreen


