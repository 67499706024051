import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table } from 'react-bootstrap'


import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import Meta from '../../components/Meta'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


export const DeeprootsScreen = ({match, history}) => {

    
    const dispatch = useDispatch()



    const [newModel, setNewModel] = useState("") 

    const [data, setData] = useState([])






    useEffect(() => {
       

    }, [])





    return(



        <div style={{marginTop:'0px'}}>

            <Meta title={'DeepRoots'} description={'A Deep Learning library to build and train simple neural networks.'}/>


            <p className='h1'>DeepRoots</p>
            <p className='h5'>DeepRoots is an open-source neural network library built in python designed to make setting up and training neural networks easy for beginners.</p>


            <hr />


            <Card style={{padding:'20px', marginBottom:'10px', border:'none'}}>

                <p className='h3'>Getting Started</p>
            
                <p className='h5'>You can find DeepRoots on github at <a style={{color:'#7b2ff7'}} href='https://github.com/pixagan/DeepRoots'>https://github.com/pixagan/DeepRoots</a></p>
            
            

            
                <SyntaxHighlighter language='bash'>
                    git clone https://github.com/pixagan/deeproots.git
                </SyntaxHighlighter>
                
                <p className='h5'>Install the dependencies</p>

                <SyntaxHighlighter language='bash'>
                    pip install -r requirements.txt
                </SyntaxHighlighter>

                <p className='h5'>Install the package locally using pip</p>

                <SyntaxHighlighter language='bash'>
                    pip install -e .
                </SyntaxHighlighter>

            </Card>

            <hr />

            <Card style={{padding:'20px', marginBottom:'10px', border:'none'}}>

                <p className='h3'>Creating a Multi Layer Perceptron</p>

                {isMobile ? (
                <img src={"/DeepRootsExampleNet.jpg"} alt={"DeepRoots Example"} style={{width:'70vw'}}/>
                ) : (
                <img src={"/DeepRootsExampleNet.jpg"} alt={"DeepRoots Example"} style={{width:'30vw'}}/>
                )}

                <hr />



                <p className='h5'>A Simple Dataset</p>

                <SyntaxHighlighter language='python'>
                    X_train = np.array([[1,2,3], [4,5,6]])
                    y_train = np.array([[1,0], [0,1]])  
                </SyntaxHighlighter>



                <p className='h5'>Create the tanh Layers</p>

                <SyntaxHighlighter language='python'>
                    L1 = LayerScalar(3, 4, 'tanh')
                    L2 = LayerScalar(4, 2, 'tanh')
                </SyntaxHighlighter>

                <p className='h5'>Creating the Network</p>

                <SyntaxHighlighter language='python'>
                    layer_list = [L1, L2]
                    net = NNScalar(layer_list)
                </SyntaxHighlighter>

                <p className='h5'>Setting up a loss function</p>

                <SyntaxHighlighter language='python'>
                    lossfun = SoftmaxCrossEntropy()
                </SyntaxHighlighter>

                <p className='h5'>Picking up a data</p>

                <SyntaxHighlighter language='python'>
                    x_act = X_train[0,:]
                    y_act = y_train[0,:]
                </SyntaxHighlighter>

                <p className='h5'> Forward pass through the network</p>

                <SyntaxHighlighter language='python'>
                    y_pred      = net.forward(x_act)
                </SyntaxHighlighter>

                <p className='h5'>Evaluating the loss function</p>

                <SyntaxHighlighter language='python'>
                    loss, dL_dy = lossfun.eval(y_act, y_pred)
                </SyntaxHighlighter>

                <p className='h5'>Backward pass through the network</p>

                <SyntaxHighlighter language='python'>
                    net.backward(dL_dy)
                </SyntaxHighlighter>

                <p className='h5'>Getting the gradients</p>

                <SyntaxHighlighter language='python'>
                    grad = net.get_gradient()
                </SyntaxHighlighter>

                <p className='h5'>Getting the weights</p>

                <SyntaxHighlighter language='python'>
                    weights = net.get_weights()
                    print("weights ", weights)
                </SyntaxHighlighter>


            </Card>

 



        </div>



    )


}

export default DeeprootsScreen


